import React from "react";

const Info_Widgets = () => {
  return (
    <section id="customer-count" className="mt-2">
      <div className="container-fluid">
        <div className="customer-count-content">
      <h2 className="text-center">How We Work?</h2>
      </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-65">
            <div className="our-process-inner about-service">
              <div>
                {" "}
                <h2><i className="fa fa-check-circle"></i> Step 1 :</h2>
                <h3>Understanding <br/> your needs</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-65">
            <div className="our-process-inner about-service">
              <div>
                {" "}
                <h2><i className="fa fa-check-circle"></i> Step 2 :</h2>
                <h3>Provide Valuable <br/> Knowledge</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-65">
            <div className="our-process-inner about-service">
              <div>
                {" "}
                <h2><i className="fa fa-check-circle"></i> Step 3 :</h2>
                <h3>Online Payment <br/> for Service</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-65">
            <div className="our-process-inner about-service">
              <div>
                {" "}
                <h2><i className="fa fa-check-circle"></i> Step 4 :</h2>
                <h3>Completion and  <br/> Satisfaction of work</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info_Widgets;
