import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InsertChartTwoToneIcon from "@mui/icons-material/InsertChartTwoTone";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

export default function New() {
  const [open, setOpen] = React.useState(null);

  const services = [
    {
      name: "COMPANY REGISTRATION SERVICES",
      subservices: [
        { name: "Private Limited Company", link: "/epicorp-private-limited-company" },
        { name: "One Person Company (OPC)", link: "/epicorp-one-person-company" },
        { name: "Limited Liability Partnership(LLP)", link: "/epicorp-limited-liability-partnership" },
        { name: "Subsidiary of Foreign Company", link: "/epicorp-subsidiary-of-foreign-company" },
        { name: "Nidhi Company", link: "/epicorp-nidhi-company" },
        { name: "Producer / Farmer Company", link: "/epicorp-producer-farmer-company" },
        { name: "NGO Company", link: "/epicorp-ngo-company" },
        {
          name: "Start Up India Registration / DPIIT Recognition",
          link: "/epicorp-start-up-india-registration",
        },
        // Add your subservices here
      ],
    },
    {
      name: "REGISTRATION & LICENCES SERVICES",
      subservices: [
        { name: "Trademark Registration", link: "/epicorp-trademark-registration" },
        { name: "MSME Registration", link: "/epicorp-msme-registration" },
        { name: "FSSAI Registration and Licences", link: "/epicorp-fssai-registration-licences" },
        { name: "GST Registration", link: "/epicorp-gst-registration" },
        {
          name: "Registration for Import & Export Businesses",
          link: "/epicorp-import-export-registrations",
        },
        { name: "Registration for NGO", link: "/epicorp-ngo-registration" },
        // Add your subservices here
      ],
    },
    {
      name: "RETURN FILING SERVICES",
      subservices: [
        { name: "Annual Filing of Companies", link: "/epicorp-annual-filing-of-companies" },
        { name: "Annual Filing of LLP's", link: "/epicorp-filing-of-llps" },
        { name: "Event Based Filing", link: "/epicorp-event-based-filing" },
        { name: "GST Filing", link: "/epicorp-gst-filing" },
        { name: "XBRL Filing", link: "/epicorp-xbrl-filing" },
        { name: "Annual Director KYC", link: "/epicorp-annual-director-kyc" },
        { name: "Secretarial Audit", link: "/epicorp-secretarial-audit" },
        // Add your subservices here
      ],
    },
    {
      name: "CORPORATE ACTIONS",
      subservices: [
        { name: "Issue & Allotment of Shares", link: "/epicorp-issue-allotment-of-shares" },
        { name: "Transfer of Shares", link: "/epicorp-transfer-of-shares" },
        { name: "Registration/Modification of Charges", link: "/epicorp-registration-modification-of-charges" },
        { name: "Change Company Name", link: "/epicorp-change-company-name" },
        { name: "Alteration of MOA & AOA", link: "/epicorp-alteration-of-moa-aoa" },
        { name: "Change of Registered address", link: "/epicorp-change-of-registered-address" },
        { name: "Changes in Directors", link: "/epicorp-changes-in-directors" },
        { name: "Change of Auditors", link: "/epicorp-change-of-auditors" },
        // Add your subservices here
      ],
    },
    {
      name: "CONVERSIONS & CLOSURE",
      subservices: [
        { name: "Closure of Company", link: "/epicorp-closure-of-company" },
        { name: "Closure of LLP", link: "/epicorp-closure-of-llp" },
        // Add your subservices here
      ],
    },
    {
      name: "SECRETARIAL & ALLIED SERVICES",
      subservices: [
        {
          name: "Drafting of Board & Special Resolution",
          link: "/epicorp-drafting-of-board-special-resolution",
        },
        {
          name: "Maintenance of Minutes of Board & General Meetings",
          link: "/epicorp-maintenance-of-minutes-of-board-general-meetings",
        },
        { name: "Maintenance of Statutory Registers", link: "/epicorp-maintenance-of-statutory-registers" },
        // Add your subservices here
      ],
    },
    // Add your services here
  ];

  const handleClick = (index) => {
    setOpen(open === index ? null : index);
  };

  return (
    <section id="our-services-m" className="hide-on-desktop mt-5"> 
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
          <div className="customer-count-content text-center platform-inner-content">
            <h2>Our Services</h2>
            <p
              className="mt-65"
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                textAlign: "center",
              }}
            >
              Our processes are digital so that we can be at your service
              irrespective of where you are.
            </p>
          </div>
        </div>
      </div>
      <div>
        <List>
          {services.map((service, index) => (
            <div key={index}>
              <ListItem button onClick={() => handleClick(index)}>
                {open === index ? (
                  <Card variant="outlined" sx={{ backgroundColor: "#09455F" }}>
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        sx={{
                          color: "#ffffff",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "18px",
                          lineHeight: "28px",
                        }}
                      >
                        {service.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        align="center"
                        sx={{ color: "#ffffff" }}
                      >
                        Select a subservice to navigate to its page.
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: "#000",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "28px",
                          borderBottom: "1px solid #FBAC18",
                        }}
                      >
                        {service.name}
                      </Typography>
                    }
                  />
                )}
                {open === index ? (
                  <ExpandLess sx={{ color: "#FBAC18" }} />
                ) : (
                  <ExpandMore sx={{ color: "#09455F" }} />
                )}
              </ListItem>
              <Collapse in={open === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {service.subservices.map((subservice, i) => (
                    <Box
                      key={i}
                      sx={{
                        backgroundColor: "white",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                        margin: "10px 0",
                        padding: "10px",
                      }}
                    >
                      <ListItem button component={Link} to={subservice.link}>
                        <ListItemIcon>
                          <InsertChartTwoToneIcon
                            sx={{ color: "#FBAC18", fontSize: "32px" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "28px",
                              }}
                            >
                              {subservice.name}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <ArrowCircleRightTwoToneIcon
                            sx={{ color: "black", fontSize: "32px" }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Box>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </div>
    </div>
    </section>
  );
}
