import QuickreplyTwoToneIcon from '@mui/icons-material/QuickreplyTwoTone';
import SentimentVerySatisfiedTwoToneIcon from '@mui/icons-material/SentimentVerySatisfiedTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import CasesTwoToneIcon from '@mui/icons-material/CasesTwoTone';
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import ImportantDevicesTwoToneIcon from '@mui/icons-material/ImportantDevicesTwoTone';

const Why_Choose_Us = () => {
  return (
    <>
      <section id="overview" className="mt-5">
        <div className="container text-center">
          <div className="customer-count-content">
            <h2>Why Choose Epicorp?</h2>
          </div>
          <div className="row pt-5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="row m-0">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>
                        <ImportantDevicesTwoToneIcon sx={{fontSize:"5rem", color:"#09455F"}}/>
                        <h3>Digital Presence</h3>
                        {/* <p>
                          Our processes are digital so that we can be at your
                          service irrespective of where you are.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>                      
                          <QuickreplyTwoToneIcon sx={{fontSize:"5rem", color:"#ff5555"}}/>                                                                            
                        <h3>Prompt and Online services</h3>
                        {/* <p>
                          With a deep understanding of theoretical concepts and
                          practical applications, our professionals are equipped
                          to handle complex financial statements, analysis, and
                          compliances with ease.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>
                        <SentimentVerySatisfiedTwoToneIcon sx={{fontSize:"5rem", color:"#4ed080"}}/>
                        <h3>Guaranteed satisfaction</h3>
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>
                        <StarTwoToneIcon sx={{fontSize:"5rem", color:"#ffbb33"}}/>
                        <h3>Affordable costs with best services</h3>
                        <p>
                          
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>
                        <CasesTwoToneIcon sx={{fontSize:"5rem", color:"#4e5fd0"}}/>
                        <h3>Clear Value Proposition</h3>
                        {/* <p>
                          We provide a one-stop solution for businesses seeking
                          to navigate the complexities of corporate governance
                          with ease and reliability.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div className="inner-overview mt-3">
                      <div>
                        <DashboardCustomizeTwoToneIcon sx={{fontSize:"5rem", color:"#4ed0ce"}}/>
                        <h3>Tailored Comprehensive Solutions</h3>
                        {/* <p>
                          Our firm is dedicated to providing comprehensive
                          solutions tailored to the unique requirements of each
                          client.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Why_Choose_Us;
