import React, { useState, useEffect } from "react";
import Info from "./Info_Widgets";
import Why from "./Why_Choose_Us";
import esi from "../images/esi-icon.webp";
import arrow from "../images/right-arrow.webp";
import bannerimg from "../images/banner-image-landing-page.webp";
import { Link } from "react-router-dom";
import { Link as RsLink } from "react-scroll";
import New from "./New";
import { useMediaQuery, useTheme } from "@mui/material";

const Home = () => {
  const [activeTab, setActiveTab] = useState("business-regisration");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const img = new Image();
    img.src = bannerimg;
  }, []);
  return (
    <div>
      <>
        <section id="search-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-85">
                <div className="search-inner text-center">
                  <h1 className="mb-5">
                    Empowering Your <span>Business Vision</span> with{" "}
                    <span>Expert Insights</span> &{" "}
                    <span>Tailored Strategies</span>
                  </h1>
                  {/* <div className="search-box">
                    <form
                      method="post"
                      action="service_search_index"
                      id="search-form"
                    >
                      <div className="position-relative">
                        <input
                          type="search"
                          id="searchKey"
                          className="form-control"
                          name="searchKey"
                          defaultValue=""
                          placeholder="Search From Our 100+ Services.."
                        />
                        <button type="submit">Search</button>
                      </div>
                    </form>
                  </div> */}
                  <div className="services-list mt-4">
                    <p>Popular Services</p>
                    <div className="recommended-services">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Link to="/epicorp-private-limited-company">
                            Private Limited Company
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-start-up-india-registration">
                            Startup India Certificate
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-ngo-company">
                            NGO Company Registration
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-import-export-registrations">
                            Import Export Registrations
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-trademark-registration">
                            Trademark Registration
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-gst-registration">
                            GST Registration
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="/epicorp-fssai-registration-licences">
                            Food Licenses
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <RsLink
                            to={
                              isSmallScreen ? "our-services-m" : "our-services"
                            }
                            offset={isSmallScreen ? -100 : -70}
                            duration={500}
                            style={{ cursor: "pointer" }}
                            tabIndex="0"
                            href="#our-services"
                          >
                            More +
                          </RsLink>
                        </li>
                      </ul>
                      <div className="platform-inner-content"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",                    
                  }}
                >
                  <Link to="/about">Learn more about our Company</Link>
                </div>
                    </div>
                  </div>
                  {/* <p className="rating-text mt-4">
                  <i className='fa fa-star' />
                    <span>
                      Rated at <strong>4.9</strong> By <strong>30000 +</strong>{" "}
                      Customers Globally
                    </span>
                  </p> */}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-5">
                <div className="banner-right">
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <img
                      src={bannerimg}
                      alt="epicorp process"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <New />
        <section id="our-services" className="hide-on-mobile">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                <div className="customer-count-content text-center">
                  <h2>Our Services</h2>
                  <p
                  className="mt-65 text-center"
                  style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  Our processes are digital so that we can be at your service
                  irrespective of where you are.
                </p>
                <p className="text-center">
                  At Epicorp, we empower businesses to navigate the complexities
                  of the market with confidence. Our team of seasoned
                  consultants brings a wealth of expertise in driving growth,
                  optimizing operations, and implementing innovative strategies
                  that deliver tangible results. We are committed to fostering
                  sustainable success for our clients, offering personalized
                  solutions that are tailored to the unique needs of each
                  business.
                </p>
                <p className="text-center">
                  Partner with us to transform your challenges into
                  opportunities and propel your business to new heights.
                </p>  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                <div className="left-tab-panel">
                  <div className="for-scroll">
                    <div
                      className="nav flex-column nav-pills"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className={`nav-link ${
                          activeTab === "business-regisration" ? "active" : ""
                        }`}
                        id="business-regisration-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#business-regisration"
                        type="button"
                        role="tab"
                        aria-controls="business-regisration"
                        aria-selected="true"
                        onClick={() => setActiveTab("business-regisration")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Company Registration Services</h4>
                          </span>{" "}
                        </div>
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "gov-reg" ? "active" : ""
                        }`}
                        id="gov-reg-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#gov-reg"
                        type="button"
                        role="tab"
                        aria-controls="gov-reg"
                        aria-selected="false"
                        onClick={() => setActiveTab("gov-reg")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Registration & Licenses Services</h4>
                          </span>{" "}
                        </div>
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "fssai-license" ? "active" : ""
                        }`}
                        id="fssai-license-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#fssai-license"
                        type="button"
                        role="tab"
                        aria-controls="fssai-license"
                        aria-selected="false"
                        onClick={() => setActiveTab("fssai-license")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Return Filing Services</h4>
                          </span>{" "}
                        </div>
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "rbi-regulatory" ? "active" : ""
                        }`}
                        id="rbi-regulatory-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#rbi-regulatory"
                        type="button"
                        role="tab"
                        aria-controls="rbi-regulatory"
                        aria-selected="false"
                        onClick={() => setActiveTab("rbi-regulatory")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Conversions & Closure</h4>
                          </span>{" "}
                        </div>
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "environment-laws" ? "active" : ""
                        }`}
                        id="environment-laws-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#environment-laws"
                        type="button"
                        role="tab"
                        aria-controls="environment-laws"
                        aria-selected="false"
                        onClick={() => setActiveTab("environment-laws")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Corporate Actions</h4>
                          </span>{" "}
                        </div>
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "tax-filings" ? "active" : ""
                        }`}
                        id="tax-filings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#tax-filings"
                        type="button"
                        role="tab"
                        aria-controls="tax-filings"
                        aria-selected="false"
                        onClick={() => setActiveTab("tax-filings")}
                      >
                        <div>
                          {" "}
                          <span>
                            <svg
                              width={22}
                              height={25}
                              viewBox="0 0 22 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.63158 24.4219H1.15789C0.850802 24.4219 0.556287 24.2888 0.339139 24.0519C0.121992 23.815 0 23.4937 0 23.1587V13.0535C0 12.7184 0.121992 12.3972 0.339139 12.1603C0.556287 11.9234 0.850802 11.7903 1.15789 11.7903H4.63158C4.93867 11.7903 5.23319 11.9234 5.45033 12.1603C5.66748 12.3972 5.78947 12.7184 5.78947 13.0535V23.1587C5.78947 23.4937 5.66748 23.815 5.45033 24.0519C5.23319 24.2888 4.93867 24.4219 4.63158 24.4219ZM12.7368 24.4219H9.26316C8.95607 24.4219 8.66155 24.2888 8.4444 24.0519C8.22726 23.815 8.10526 23.4937 8.10526 23.1587V1.68503C8.10526 1.35002 8.22726 1.02873 8.4444 0.791845C8.66155 0.554957 8.95607 0.421875 9.26316 0.421875H12.7368C13.0439 0.421875 13.3384 0.554957 13.5556 0.791845C13.7727 1.02873 13.8947 1.35002 13.8947 1.68503V23.1587C13.8947 23.4937 13.7727 23.815 13.5556 24.0519C13.3384 24.2888 13.0439 24.4219 12.7368 24.4219ZM20.8421 24.4219H17.3684C17.0613 24.4219 16.7668 24.2888 16.5497 24.0519C16.3325 23.815 16.2105 23.4937 16.2105 23.1587V9.26398C16.2105 8.92897 16.3325 8.60768 16.5497 8.37079C16.7668 8.1339 17.0613 8.00082 17.3684 8.00082H20.8421C21.1492 8.00082 21.4437 8.1339 21.6609 8.37079C21.878 8.60768 22 8.92897 22 9.26398V23.1587C22 23.4937 21.878 23.815 21.6609 24.0519C21.4437 24.2888 21.1492 24.4219 20.8421 24.4219Z"
                                fill="white"
                              />
                            </svg>
                          </span>{" "}
                          <span>
                            <h4>Secretarial & Allied Services</h4>
                          </span>{" "}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      activeTab === "business-regisration" ? "show active" : ""
                    }`}
                    id="business-regisration"
                    role="tabpanel"
                    aria-labelledby="business-regisration-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Private Limited Company</h3>
                            </div>
                            <Link
                              to="/epicorp-private-limited-company"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">One Person Company</h3>
                            </div>
                            <Link
                              to="/epicorp-one-person-company"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Limited Liability Partnership (LLP)
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-limited-liability-partnership"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Nidhi Company</h3>
                            </div>
                            <Link
                              to="/epicorp-nidhi-company"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Producer/Farmer Company</h3>
                            </div>
                            <Link
                              to="/epicorp-producer-farmer-company"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Subsidiary/Wholly Owned Subsidiary Company
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-subsidiary-of-foreign-company"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">NGO / Section 8 Company</h3>
                            </div>
                            <Link
                              to="/epicorp-ngo-company"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Startup India Certificate
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-start-up-india-registration"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "gov-reg" ? "show active" : ""
                    }`}
                    id="gov-reg"
                    role="tabpanel"
                    aria-labelledby="gov-reg-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Trademark Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-trademark-registration"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">MSME Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-msme-registration"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Food License (Fssai Registration)
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-fssai-registration-licences"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="One Person Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Goods & Service Tax (GST Registration)
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-gst-registration"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="One Person Company Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">
                                Import Export Code (IEC Registration)
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-import-export-code-registration"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">APEDA Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-apeda-registration"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">RCMC Registration/FIEO</h3>
                            </div>
                            <Link
                              to="/epicorp-rcmc-certificate-fieo"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">Spices Board Certificate</h3>
                            </div>
                            <Link
                              to="/epicorp-spices-board-certificate"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">12A & 80G Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-ngo-registration#sec1"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                NGO Darpan/NITI Ayog Registration
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-ngo-registration#sec2"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">E Anudaan Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-ngo-registration#sec3"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">CSR Registration</h3>
                            </div>
                            <Link
                              to="/epicorp-ngo-registration#sec4"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "fssai-license" ? "show active" : ""
                    }`}
                    id="fssai-license"
                    role="tabpanel"
                    aria-labelledby="fssai-license-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Annual Filing of Companies
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-annual-filing-of-companies"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">Annual Filing of LLPs</h3>
                            </div>
                            <Link
                              to="/epicorp-filing-of-llps"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">Event Based Filing</h3>
                            </div>
                            <Link
                              to="/epicorp-event-based-filing"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="One Person Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">XBRL Filing</h3>
                            </div>
                            <Link
                              to="/epicorp-xbrl-filing"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="One Person Company Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Annual Director KYC</h3>
                            </div>
                            <Link
                              to="/epicorp-annual-director-kyc"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">GST Returns</h3>
                            </div>
                            <Link
                              to="/epicorp-gst-filing"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Secretarial Audit</h3>
                            </div>
                            <Link
                              to="/epicorp-secretarial-audit"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "rbi-regulatory" ? "show active" : ""
                    }`}
                    id="rbi-regulatory"
                    role="tabpanel"
                    aria-labelledby="rbi-regulatory-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Closure of Company</h3>
                            </div>
                            <Link
                              to="/epicorp-closure-of-company"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">Closure of LLP</h3>
                            </div>
                            <Link
                              to="/epicorp-closure-of-llp"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "environment-laws" ? "show active" : ""
                    }`}
                    id="environment-laws"
                    role="tabpanel"
                    aria-labelledby="environment-laws-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Issue & Allotment of Shares
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-issue-allotment-of-shares"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">Transfer of Shares</h3>
                            </div>
                            <Link
                              to="/epicorp-transfer-of-shares"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="One Person Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Registration / Modification of Charges
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-registration-modification-of-charges"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="One Person Company Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Change Company Name</h3>
                            </div>
                            <Link
                              to="/epicorp-change-company-name"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Alteration of MOA & AOA</h3>
                            </div>
                            <Link
                              to="/epicorp-alteration-of-moa-aoa"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Change of Registered address
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-change-of-registered-address"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Changes in Directors</h3>
                            </div>
                            <Link
                              to="/epicorp-changes-in-directors"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Partnership Firm Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">Change of Auditors</h3>
                            </div>
                            <Link
                              to="/epicorp-change-of-auditors"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="Partnership Firm Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "tax-filings" ? "show active" : ""
                    }`}
                    id="tax-filings"
                    role="tabpanel"
                    aria-labelledby="tax-filings-tab"
                  >
                    <div className="inner-tab-panel">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Drafting of Board & Special Resolution
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-drafting-of-board-special-resolution"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="Company Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img src={esi} alt="LLP Registration" />{" "}
                              </div>
                              <h3 className="ms-4">
                                Maintenance of Minutes of Board & General
                                Meetings
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-maintenance-of-minutes-of-board-general-meetings"
                              className="go-to-service"
                            >
                              <img src={arrow} alt="LLP Registration" />
                            </Link>{" "}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-36">
                          <div className="about-service">
                            <div className="d-flex align-items-center">
                              <div className="position-relative border-after">
                                {" "}
                                <img
                                  src={esi}
                                  alt="One Person Company Registration"
                                />{" "}
                              </div>
                              <h3 className="ms-4">
                                Maintenance of Statutory Registers
                              </h3>
                            </div>
                            <Link
                              to="/epicorp-maintenance-of-statutory-registers"
                              className="go-to-service"
                            >
                              <img
                                src={arrow}
                                alt="One Person Company Registration"
                              />
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <section id="service-platform">
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
              <div className="platform-inner-content customer-count-content">
                <h2 className="mb-5 text-center">BUY OUR PACKAGES TO SAVE COSTS !!!</h2>
                              
              </div>
            </div>
            <Packages />
          </div> */}
        </div>
      </section>
      <Info />
      <Why />
    </div>
  );
};

export default Home;
