import React, { useState, useEffect, useRef } from "react";
import img from "../images/epicorp-logo-white-bg.webp";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenStartup, setIsOpenStartup] = useState(false);
  const [isOpenLicense, setIsOpenLicense] = useState(false);
  const [isOpenTax, setIsOpenTax] = useState(false);
  const [isOpenRegulatory, setIsOpenRegulatory] = useState(false);
  const [isOpenEnvironmental, setIsOpenEnvironmental] = useState(false);
  const [isOpenSecretarial, setIsOpenSecretarial] = useState(false);
  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [activeTab, setActiveTab] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  //Mobile functions
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);

  const [isOpenRegLic, setIsOpenRegLic] = useState(false);
  const toggleDropdownRegLic = (event) => {
    event.preventDefault();
    setIsOpenRegLic(!isOpenRegLic);
    if (
      isOpenStartup ||
      isOpenGst ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenSecretarial
    ) {
      setIsOpenStartup(false);
      setIsOpenGst(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
    }
  };
  const [isOpenGst, setIsOpenGst] = useState(false);
  const toggleDropdownGst = (event) => {
    event.preventDefault();
    setIsOpenGst(!isOpenGst);
    if (
      isOpenStartup ||
      isOpenRegLic ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenSecretarial
    ) {
      setIsOpenStartup(false);
      setIsOpenRegLic(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
    }
  };

  const toggleShowDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the event from bubbling up
    setShowDropdown(!showDropdown);
    setShowDropdown2(false); // Close the other dropdowns
    setShowDropdown3(false); // Close the other dropdowns
  };

  const toggleShowDropdown2 = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the event from bubbling up
    setShowDropdown2(!showDropdown2);
    setShowDropdown(false); // Close the other dropdowns
    setShowDropdown3(false); // Close the other dropdowns
  };

  const toggleShowDropdown3 = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the event from bubbling up
    setShowDropdown3(!showDropdown3);
    setShowDropdown(false); // Close the other dropdowns
    setShowDropdown2(false); // Close the other dropdowns
  };

  const toggleShowDropdown4 = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the event from bubbling up
    setShowDropdown4(!showDropdown4);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenStartup(false);
        setIsOpenLicense(false);
        setIsOpenTax(false);
        setIsOpenRegulatory(false);
        setIsOpenEnvironmental(false);
        setIsOpenSecretarial(false);
        setIsOpenRegLic(false);
        setIsOpenGst(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    closeAllDropdowns();
    setIsOpen(false);
  }, [location]);

  const closeAllDropdowns = () => {
    setIsOpenStartup(false);
    setIsOpenLicense(false);
    setIsOpenTax(false);
    setIsOpenRegulatory(false);
    setIsOpenEnvironmental(false);
    setIsOpenSecretarial(false);
    setIsOpenRegLic(false);
    setIsOpenGst(false);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const toggleDropdownStartup = (event) => {
    event.preventDefault();
    setIsOpenStartup(!isOpenStartup);
    if (
      isOpenLicense ||
      isOpenTax ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenSecretarial ||
      isOpenRegLic ||
      isOpenGst
    ) {
      setIsOpenLicense(false);
      setIsOpenTax(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
      setIsOpenRegLic(false);
      setIsOpenGst(false);
    }
  };

  const toggleDropdownLicense = (event) => {
    event.preventDefault();
    setIsOpenLicense(!isOpenLicense);
    if (
      isOpenStartup ||
      isOpenTax ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenSecretarial
    ) {
      setIsOpenStartup(false);
      setIsOpenTax(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
    }
  };

  const toggleDropdownTax = (event) => {
    event.preventDefault();
    setIsOpenTax(!isOpenTax);
    if (
      isOpenStartup ||
      isOpenLicense ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenSecretarial
    ) {
      setIsOpenStartup(false);
      setIsOpenLicense(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
    }
  };

  const toggleDropdownRegulatory = (event) => {
    event.preventDefault();
    setIsOpenRegulatory(!isOpenRegulatory);
    if (
      isOpenStartup ||
      isOpenLicense ||
      isOpenTax ||
      isOpenEnvironmental ||
      isOpenSecretarial ||
      isOpenRegLic ||
      isOpenGst
    ) {
      setIsOpenStartup(false);
      setIsOpenLicense(false);
      setIsOpenTax(false);
      setIsOpenEnvironmental(false);
      setIsOpenSecretarial(false);
      setIsOpenRegLic(false);
      setIsOpenGst(false);
    }
  };

  const toggleDropdownEnvironmental = (event) => {
    event.preventDefault();
    setIsOpenEnvironmental(!isOpenEnvironmental);
    if (
      isOpenStartup ||
      isOpenLicense ||
      isOpenTax ||
      isOpenRegulatory ||
      isOpenSecretarial ||
      isOpenRegLic ||
      isOpenGst
    ) {
      setIsOpenStartup(false);
      setIsOpenLicense(false);
      setIsOpenTax(false);
      setIsOpenRegulatory(false);
      setIsOpenSecretarial(false);
      setIsOpenRegLic(false);
      setIsOpenGst(false);
    }
  };

  const toggleDropdownSecretarial = (event) => {
    event.preventDefault();
    setIsOpenSecretarial(!isOpenSecretarial);
    if (
      isOpenStartup ||
      isOpenLicense ||
      isOpenTax ||
      isOpenRegulatory ||
      isOpenEnvironmental ||
      isOpenRegLic ||
      isOpenGst
    ) {
      setIsOpenStartup(false);
      setIsOpenLicense(false);
      setIsOpenTax(false);
      setIsOpenRegulatory(false);
      setIsOpenEnvironmental(false);
      setIsOpenRegLic(false);
      setIsOpenGst(false);
    }
  };

  const toggleDropdownCompany = (event) => {
    event.preventDefault();
    setIsOpenCompany(!isOpenCompany);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`navbar-placeholder ${scrolled ? "show" : ""}`}></div>
      <nav
        className={`navbar navbar-expand-xl ${scrolled ? "fixed" : ""}`}
        onMouseLeave={closeAllDropdowns}
      >
        <div className="container-fluid">
          {" "}
          <Link
            className="navbar-brand"
            aria-label="Epicorp Home Page"
            to="/"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img src={img} alt="Epicorp" />
          </Link>
          <Link
            className={`navbar-toggler fa fa-2x ${
              isOpen ? "fa-close" : "fa-bars"
            }`}
            style={{
              color: "black",
              backgroundColor: "#E5E7EB", // Change this to your desired color
              borderRadius: "30px",
              padding: "10px 15px",
            }}
            type="button"
            onClick={toggleDropdown}
            aria-controls="navbarTogglerDemo02"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          ></Link>
          <div
            className={`navbar-collapse ${isOpen ? "show" : ""}`}
            id="navbarTogglerDemo02"
            ref={dropdownRef}
          >
            <ul className="navbar-nav nav-epicorp ms-auto my-2 my-lg-0 navbar-nav-scroll align-items-center hide-on-mobile">
              <li className="nav-item">
                <div className="dropdown" onMouseLeave={toggleDropdownStartup}>
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown"
                    data={1}
                    onMouseEnter={toggleDropdownStartup}
                  >
                    Company <br />
                    Registration
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new "
                    style={{ display: isOpenStartup ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-11" ? "active" : ""
                            }`}
                            id="v-pills-11-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-11"
                            role="tab"
                            aria-controls="v-pills-11"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-11")}
                            to="/epicorp-private-limited-company"
                          >
                            PRIVATE LIMITED COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-12" ? "active" : ""
                            }`}
                            id="v-pills-12-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-12"
                            role="tab"
                            aria-controls="v-pills-12"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-12")}
                            to="/epicorp-one-person-company"
                          >
                            ONE PERSON COMPANY (OPC){" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-13" ? "active" : ""
                            }`}
                            id="v-pills-13-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-13"
                            role="tab"
                            aria-controls="v-pills-13"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-13")}
                            to="/epicorp-limited-liability-partnership"
                          >
                            LIMITED LIABILITY PARTNERSHIP (LLP)&nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-14" ? "active" : ""
                            }`}
                            id="v-pills-14-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-14"
                            role="tab"
                            aria-controls="v-pills-14"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-14")}
                            to="/epicorp-subsidiary-of-foreign-company"
                          >
                            SUBSIDIARY OF FOREIGN COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-15" ? "active" : ""
                            }`}
                            id="v-pills-15-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-15"
                            role="tab"
                            aria-controls="v-pills-15"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-15")}
                            to="/epicorp-nidhi-company"
                          >
                            NIDHI COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-16" ? "active" : ""
                            }`}
                            id="v-pills-16-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-16"
                            role="tab"
                            aria-controls="v-pills-16"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-16")}
                            to="/epicorp-producer-farmer-company"
                          >
                            PRODUCER / FARMER COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-17" ? "active" : ""
                            }`}
                            id="v-pills-17-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-17"
                            role="tab"
                            aria-controls="v-pills-17"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-17")}
                            to="/epicorp-ngo-company"
                          >
                            NGO COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-19" ? "active" : ""
                            }`}
                            id="v-pills-19-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-19"
                            role="tab"
                            aria-controls="v-pills-19"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-19")}
                            to="/epicorp-start-up-india-registration"
                          >
                            START UP INDIA REGISTRATION / DPIIT RECOGNITION
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown" onMouseLeave={toggleDropdownLicense}>
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown "
                    data={2}
                    onMouseEnter={toggleDropdownLicense}
                  >
                    Registration
                    <br /> & Licences
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new newdropdow2"
                    style={{ display: isOpenLicense ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow2 ${
                              activeTab === "v-pills-21" ? "active" : ""
                            }`}
                            id="v-pills-21-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-21"
                            role="tab"
                            aria-controls="v-pills-21"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-21")}
                            to="/epicorp-trademark-registration"
                          >
                            TRADEMARK REGISTRATION
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-22" ? "active" : ""
                            }`}
                            id="v-pills-22-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-22"
                            role="tab"
                            aria-controls="v-pills-22"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-22")}
                            to="/epicorp-msme-registration"
                          >
                            MSME REGISTRATION
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-23" ? "active" : ""
                            }`}
                            id="v-pills-23-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-23"
                            role="tab"
                            aria-controls="v-pills-23"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-23")}
                            to="/epicorp-fssai-registration-licences"
                          >
                            FSSAI REGISTRATION AND LICENCES
                            <i className="fa fa-angle-right" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-24" ? "active" : ""
                            }`}
                            id="v-pills-24-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-24"
                            role="tab"
                            aria-controls="v-pills-24"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-24")}
                            to="/epicorp-gst-registration"
                          >
                            GST REGISTRATION
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-25" ? "active" : ""
                            }`}
                            id="v-pills-25-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-25"
                            role="tab"
                            aria-controls="v-pills-25"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-25")}
                            to="/epicorp-import-export-registrations"
                          >
                            REGISTRATION FOR IMPORT <br />& EXPORT BUSINESSES{" "}
                            <i className="fa fa-angle-right" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow1 ${
                              activeTab === "v-pills-26" ? "active" : ""
                            }`}
                            id="v-pills-26-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-26"
                            role="tab"
                            aria-controls="v-pills-26"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-26")}
                            to="/epicorp-ngo-registration"
                          >
                            REGISTRATION FOR NGO{" "}
                            <i className="fa fa-angle-right" />
                          </Link>
                        </li>
                      </div>
                      <div className="tab-content" id="v-pills-tab-menuContent">
                        <div
                          className={`tab-pane fade show ${
                            activeTab === "v-pills-23" ? "active" : ""
                          }`}
                          id="v-pills-23"
                          role="tabpanel"
                          aria-labelledby="v-pills-23-tab"
                          tabIndex={2}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <Link to="/epicorp-fssai-registration-licences">
                                <p>FSSAI REGISTRATION AND LICENCES</p>
                              </Link>
                              <ul className="m-l">
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec1">
                                    FOOD LICENCE FOR TRANSPORTERS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec2">
                                    FOOD LICENCE FOR IMPORTERS, EXPORTERS &
                                    E-COMMERCE FOOD SUPPLIERS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec3">
                                    FOOD LICENCE FOR
                                    RESTAURANTS/CLUBS/CANTEENS/CATERERS/HOTELS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec4">
                                    FOOD LICENCE FOR DISTRIBUTORS, RETAILERS &
                                    WHOLESALERS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec5">
                                    FOOD LICENCE FOR DHABA/BOARDING
                                    HOUSES/BANQUET HALLS/HOME BASED
                                    CANTEENS/PERMANENT OR
                                    <br /> TEMPORARY STALL HOLDERS/FOOD STALLS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec6">
                                    FOOD LICENCE FOR MANUFACTURERS
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-fssai-registration-licences#sec7">
                                    FSSAI FAQs
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeTab === "v-pills-25" ? "active" : ""
                          }`}
                          id="v-pills-25"
                          role="tabpanel"
                          aria-labelledby="v-pills-25-tab"
                          tabIndex={4}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <Link to="/epicorp-import-export-registrations">
                                <p>
                                  REGISTRATION FOR IMPORT & EXPORT BUSINESSES
                                </p>
                              </Link>
                              <ul className="m-l">
                                <li>
                                  <Link to="/epicorp-import-export-code-registration">
                                    IMPORT EXPORT CODE (IEC) REGISTRATION
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-apeda-registration">
                                    APEDA REGISTRATION
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-rcmc-certificate-fieo">
                                    RCMC CERTIFICATE/FIEO
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-spices-board-certificate">
                                    SPICES BOARD CERTIFICATE
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeTab === "v-pills-26" ? "active" : ""
                          }`}
                          id="v-pills-26"
                          role="tabpanel"
                          aria-labelledby="v-pills-26-tab"
                          tabIndex={5}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                              <Link to="/epicorp-ngo-registration">
                                <p>REGISTRATION FOR NGO</p>
                              </Link>
                              <ul className="m-l">
                                <li>
                                  <Link to="/epicorp-ngo-registration#sec1">
                                    12A & 80G REGISTRATION
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-ngo-registration#sec2">
                                    NGO DARPAN/NITI AYOG REGISTRATION
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-ngo-registration#sec3">
                                    E ANUDAAN REGISTRATION
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/epicorp-ngo-registration#sec4">
                                    CSR REGISTRATION
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeTab === "v-pills-27" ? "active" : ""
                          }`}
                          id="v-pills-27"
                          role="tabpanel"
                          aria-labelledby="v-pills-27-tab"
                          tabIndex={6}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                              <Link to="government-licenses">
                                <p>Registrations for NGO</p>
                              </Link>
                              <ul>
                                <li>
                                  <Link to="ce-certification">
                                    12A & 80G registration
                                  </Link>
                                </li>
                                <li>
                                  <Link to="psara-license">
                                    Niti Ayog Registration
                                  </Link>
                                </li>
                                <li>
                                  <Link to="trade-license">
                                    E Anudan Registration
                                  </Link>
                                </li>
                                <li>
                                  <Link to="music-license">
                                    CSR Registration
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeTab === "v-pills-28" ? "active" : ""
                          }`}
                          id="v-pills-28"
                          role="tabpanel"
                          aria-labelledby="v-pills-28-tab"
                          tabIndex={7}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                              <Link to="government-licenses">
                                <p>
                                  Registration for Import & Export Businesses
                                </p>
                              </Link>
                              <ul>
                                <li>
                                  <Link to="ce-certification">
                                    APEDA Registration
                                  </Link>
                                </li>
                                <li>
                                  <Link to="psara-license">
                                    Spice Board Registration
                                  </Link>
                                </li>
                                <li>
                                  <Link to="trade-license">
                                    RCMC/FIEO Certificate
                                  </Link>
                                </li>
                                <li>
                                  <Link to="music-license">
                                    Coffee/Tea Board Registration
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown" onMouseLeave={toggleDropdownTax}>
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown "
                    data={3}
                    onMouseEnter={toggleDropdownTax}
                  >
                    Return
                    <br /> Filing
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new newdropdow3"
                    style={{ display: isOpenTax ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow3 ${
                              activeTab === "v-pills-31" ? "active" : ""
                            }`}
                            id="v-pills-31-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-31"
                            role="tab"
                            aria-controls="v-pills-31"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-31")}
                            to="/epicorp-annual-filing-of-companies"
                          >
                            ANNUAL FILING OF COMPANIES
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow3 ${
                              activeTab === "v-pills-32" ? "active" : ""
                            }`}
                            id="v-pills-32-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-32"
                            role="tab"
                            aria-controls="v-pills-32"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-32")}
                            to="/epicorp-filing-of-llps"
                          >
                            ANNUAL FILING OF LLP'S                            
                          </Link>
                        </li>
                        <li>
                        <Link
                          className={`nav-link dropdown-item activedropdow3 ${
                            activeTab === "v-pills-33" ? "active" : ""
                          }`}
                          id="v-pills-33-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-33"
                          role="tab"
                          aria-controls="v-pills-33"
                          aria-selected="true"
                          onMouseEnter={() => setActiveTab("v-pills-33")}
                          to="/epicorp-event-based-filing"
                        >
                          EVENT BASED FILING
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`nav-link dropdown-item activedropdow3 ${
                            activeTab === "v-pills-34" ? "active" : ""
                          }`}
                          id="v-pills-34-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-34"
                          role="tab"
                          aria-controls="v-pills-34"
                          aria-selected="true"
                          onMouseEnter={() => setActiveTab("v-pills-34")}
                          to="/epicorp-gst-filing"
                        >
                          GST FILING
                        </Link>
                      </li>

                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow3 ${
                              activeTab === "v-pills-35" ? "active" : ""
                            }`}
                            id="v-pills-35-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-35"
                            role="tab"
                            aria-controls="v-pills-35"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-35")}
                            to="/epicorp-xbrl-filing"
                          >
                            XBRL FILING{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow3 ${
                              activeTab === "v-pills-36" ? "active" : ""
                            }`}
                            id="v-pills-36-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-36"
                            role="tab"
                            aria-controls="v-pills-36"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-36")}
                            to="/epicorp-annual-director-kyc"
                          >
                            ANNUAL DIRECTOR KYC
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow3 ${
                              activeTab === "v-pills-37" ? "active" : ""
                            }`}
                            id="v-pills-37-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-37"
                            role="tab"
                            aria-controls="v-pills-37"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-37")}
                            to="/epicorp-secretarial-audit"
                          >
                            SECRETARIAL AUDIT
                          </Link>
                        </li>
                      </div>
                      <div className="tab-content" id="v-pills-tab-menuContent">
                      <div
                        className={`tab-pane fade show ${
                          activeTab === "v-pills-33" ? "active" : ""
                        }`}
                        id="v-pills-33"
                        role="tabpanel"
                        aria-labelledby="v-pills-33-tab"
                        tabIndex={3}
                      >
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <Link to="/epicorp-event-based-filing">
                              <p>EVENT BASED FILING</p>
                            </Link>
                            <ul>
                              <li>
                                <Link to="/epicorp-event-based-filing#sec1">
                                  CHANGE OF OBJECTS OF LLP
                                </Link>
                              </li>
                              <li>
                                <Link to="/epicorp-event-based-filing#sec2">
                                  ADDITION OF NEW PARTNERS IN LLP
                                </Link>
                              </li>
                              <li>
                                <Link to="/epicorp-event-based-filing#sec3">
                                  CHANGE OF NAME OF LLP
                                </Link>
                              </li>
                              <li>
                                <Link to="/epicorp-event-based-filing#sec4">
                                  CHANGE OF REGSITERED OFFICE OF LLP
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="dropdown"
                  onMouseLeave={toggleDropdownRegulatory}
                >
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown "
                    data={4}
                    onMouseEnter={toggleDropdownRegulatory}
                  >
                    Corporate
                    <br /> Actions
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new 2"
                    style={{ display: isOpenRegulatory ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-41" ? "active" : ""
                            }`}
                            id="v-pills-41-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-41"
                            role="tab"
                            aria-controls="v-pills-41"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-41")}
                            to="/epicorp-issue-allotment-of-shares"
                          >
                            ISSUE AND ALLOTMENT OF SHARES{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-42" ? "active" : ""
                            }`}
                            id="v-pills-42-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-42"
                            role="tab"
                            aria-controls="v-pills-42"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-42")}
                            to="/epicorp-transfer-of-shares"
                          >
                            TRANSFER OF SHARES
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-43" ? "active" : ""
                            }`}
                            id="v-pills-43-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-43"
                            role="tab"
                            aria-controls="v-pills-43"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-43")}
                            to="/epicorp-registration-modification-of-charges"
                          >
                            REGISTRATION/MODIFICATION OF CHARGES
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-44" ? "active" : ""
                            }`}
                            id="v-pills-44-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-44"
                            role="tab"
                            aria-controls="v-pills-44"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-44")}
                            to="/epicorp-change-company-name"
                          >
                            CHANGE OF NAME OF THE COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-45" ? "active" : ""
                            }`}
                            id="v-pills-45-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-45"
                            role="tab"
                            aria-controls="v-pills-45"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-45")}
                            to="/epicorp-alteration-of-moa-aoa"
                          >
                            ALTERATION OF MOA AND AOA OF THE COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-46" ? "active" : ""
                            }`}
                            id="v-pills-46-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-46"
                            role="tab"
                            aria-controls="v-pills-46"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-46")}
                            to="/epicorp-change-of-registered-address"
                          >
                            CHANGE OF REGISTERED OFFICE OF THE COMPANY
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-47" ? "active" : ""
                            }`}
                            id="v-pills-47-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-47"
                            role="tab"
                            aria-controls="v-pills-47"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-47")}
                            to="/epicorp-changes-in-directors"
                          >
                            CHANGES IN DIRECTORS
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow4 ${
                              activeTab === "v-pills-48" ? "active" : ""
                            }`}
                            id="v-pills-48-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-48"
                            role="tab"
                            aria-controls="v-pills-48"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-48")}
                            to="/epicorp-change-of-auditors"
                          >
                            CHANGE OF AUDITORS
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="dropdown"
                  onMouseLeave={toggleDropdownEnvironmental}
                >
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown "
                    data={5}
                    onMouseEnter={toggleDropdownEnvironmental}
                  >
                    Conversions
                    <br /> & Closure
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new 3"
                    style={{ display: isOpenEnvironmental ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow5 ${
                              activeTab === "v-pills-51" ? "active" : ""
                            }`}
                            id="v-pills-51-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-51"
                            role="tab"
                            aria-controls="v-pills-51"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-51")}
                            to="/epicorp-closure-of-company"
                          >
                            CLOSURE OF COMPANY{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow5 ${
                              activeTab === "v-pills-52" ? "active" : ""
                            }`}
                            id="v-pills-52-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-52"
                            role="tab"
                            aria-controls="v-pills-52"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-52")}
                            to="/epicorp-closure-of-llp"
                          >
                            CLOSURE OF LLP
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="dropdown"
                  onMouseLeave={toggleDropdownSecretarial}
                >
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle nav-border navdropdown "
                    data={6}
                    onMouseEnter={toggleDropdownSecretarial}
                  >
                    Secretarial
                    <br /> & Allied
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-new 4"
                    style={{ display: isOpenSecretarial ? "block" : "none" }}
                  >
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab-menu"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow6 ${
                              activeTab === "v-pills-61" ? "active" : ""
                            }`}
                            id="v-pills-61-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-61"
                            role="tab"
                            aria-controls="v-pills-61"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-61")}
                            to="/epicorp-drafting-of-board-special-resolution"
                          >
                            DRAFTING OF BOARD RESOLUTION AND SPECIAL RESOLUTION{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow6 ${
                              activeTab === "v-pills-62" ? "active" : ""
                            }`}
                            id="v-pills-62-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-62"
                            role="tab"
                            aria-controls="v-pills-62"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-62")}
                            to="/epicorp-maintenance-of-minutes-of-board-general-meetings"
                          >
                            MAINTENANCE OF MINUTES OF BOARD AND GENERAL MEETINGS
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link dropdown-item activedropdow6 ${
                              activeTab === "v-pills-63" ? "active" : ""
                            }`}
                            id="v-pills-63-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-63"
                            role="tab"
                            aria-controls="v-pills-63"
                            aria-selected="true"
                            onMouseEnter={() => setActiveTab("v-pills-63")}
                            to="/epicorp-maintenance-of-statutory-registers"
                          >
                            MAINTENANCE OF STATUTORY REGISTERS
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>
              <li className="nav-item hide-me-mobile">
                <div className="d-flex justify-content-between">
                  <Link
                    to="https://api.whatsapp.com/send?phone=+917048933772&text=Hi Epicorp"
                    className="nav-link px-2"                    
                  >
                    <i className="fa fa-whatsapp fa-2x" />
                  </Link>                  
                </div>
              </li>
              <li className="nav-item hide-me-mobile">
                <div className="d-flex justify-content-between">
                  
                  <Link
                    to="tel:+917048933772"
                    className="nav-link px-2"                   
                  >
                    <i className="fa fa-phone fa-2x" />
                  </Link>
                </div>
              </li>
              <li className="nav-item nav-item dropdown">
                {" "}
                <Link
                  className="nav-link dropdown-toggle after-none px-2"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-label="dropdown"
                  onClick={toggleDropdownCompany}
                >
                  <span className="hide-on-desktop">Company</span>
                  <i
                    className={`fa ${
                      isOpenCompany ? "fa-close" : "fa-bars"
                    } fa-2x`}                   
                  />
                </Link>
                <ul
                  className="dropdown-menu drop-more"
                  aria-labelledby="navbarDropdown"
                  style={{ display: isOpenCompany ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/about"
                      onClick={() => {
                        setIsOpenCompany(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/contact"
                      onClick={() => {
                        setIsOpenCompany(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            {/* Mobile Navbar*/}

            <ul className="navbar-nav hide-on-desktop">
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={1}
                  onClick={toggleDropdownStartup}
                >
                  Company Registration <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show1"
                  style={{ display: isOpenStartup ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-private-limited-company"
                      data="11_1"
                    >
                      PRIVATE LIMITED COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-one-person-company"
                      data="12_1"
                    >
                      ONE PERSON COMPANY (OPC)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-limited-liability-partnership"
                      data="13_1"
                    >
                      LIMITED LIABILITY PARTNERSHIP (LLP)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-subsidiary-of-foreign-company"
                      data="123_1"
                    >
                      SUBSIDIARY OF FOREIGN COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-nidhi-company"
                      data="39_1"
                    >
                      NIDHI COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-producer-farmer-company"
                      data="121_1"
                    >
                      PRODUCER / FARMER COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-ngo-company"
                      data="741_1"
                    >
                      NGO COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-start-up-india-registration"
                      data="741_1"
                    >
                      START UP INDIA REGISTRATION
                      <br />/ DPIIT RECOGNITION
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={2}
                  onClick={toggleDropdownRegLic}
                >
                  Registration & Licences{" "}
                  <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show2"
                  style={{ display: isOpenRegLic ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-trademark-registration"
                      data="34_2"
                    >
                      TRADEMARK REGISTRATION
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-msme-registration"
                      data="117_2"
                    >
                      MSME REGISTRATION
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu"
                      onClick={toggleShowDropdown}
                      to="/epicorp-fssai-registration-licences"
                    >
                      FSSAI REGISTRATION
                      <br /> AND LICENCES
                    </Link>
                    <ul
                      className={`dropdown-menu custom_moblie ${
                        showDropdown ? "show" : ""
                      }`}
                    >
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec1">
                          FOOD LICENCE FOR TRANSPORTERS
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec2">
                          FOOD LICENCE FOR IMPORTERS,
                          <br /> EXPORTERS & E-COMMERCE
                          <br /> FOOD SUPPLIERS
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec3">
                          FOOD LICENCE FOR RESTAURANTS
                          <br />
                          /CLUBS/CANTEENS
                          <br />
                          /CATERERS/HOTELS
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec4">
                          FOOD LICENCE FOR DISTRIBUTORS,
                          <br /> RETAILERS & WHOLESALERS
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec5">
                          FOOD LICENCE FOR DHABA/BOARDING HOUSES/
                          <br />
                          BANQUET HALLS/HOME BASED
                          <br /> CANTEENS/PERMANENT OR
                          <br /> TEMPORARY STALL <br />
                          HOLDERS/FOOD STALLS
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-fssai-registration-licences#sec6">
                          FOOD LICENCE FOR MANUFACTURERS
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-gst-registration"
                      data="33_2"
                    >
                      GST REGISTRATION
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu"
                      onClick={toggleShowDropdown2}
                    >
                      REGISTRATION FOR IMPORT <br />& EXPORT BUSINESSES
                    </Link>
                    <ul
                      className={`dropdown-menu custom_moblie ${
                        showDropdown2 ? "show" : ""
                      }`}
                    >
                      <li>
                        <Link className="dropdown-item" to="/epicorp-import-export-code-registration">
                          IMPORT EXPORT CODE (IEC) REGISTRATION
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-apeda-registration">
                          APEDA REGISTRATION
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-rcmc-certificate-fieo">
                          RCMC CERTIFICATE/FIEO
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-spices-board-certificate">
                          SPICES BOARD CERTIFICATE
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu"
                      onClick={toggleShowDropdown3}
                    >
                      REGISTRATION FOR NGO
                    </Link>
                    <ul
                      className={`dropdown-menu custom_moblie ${
                        showDropdown3 ? "show" : ""
                      }`}
                    >
                      <li>
                        <Link className="dropdown-item" to="/epicorp-ngo-registration#sec1">
                          12A & 80G REGISTRATION
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-ngo-registration#sec2">
                          NGO DARPAN/NITI AYOG REGISTRATION
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-ngo-registration#sec3">
                          E ANUDAAN REGISTRATION
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-ngo-registration#sec4">
                          CSR REGISTRATION
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={3}
                  onClick={toggleDropdownGst}
                >
                  Return Filing <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show3"
                  style={{ display: isOpenGst ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-annual-filing-of-companies"
                    >
                      ANNUAL FILING OF COMPANIES
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-filing-of-llps"
                      
                      data="20_3"
                    >
                      ANNUAL FILING OF LLP{" "}
                    </Link>                    
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu"
                      onClick={toggleShowDropdown4}
                    >
                      Event Based FILING
                    </Link>
                    <ul
                      className={`dropdown-menu custom_moblie ${
                        showDropdown4 ? "show" : ""
                      }`}
                    >                      
                      <li>
                        <Link className="dropdown-item" to="/epicorp-event-based-filing#sec1">
                          CHANGE OF OBJECTS OF LLP
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-event-based-filing#sec2">
                          ADDITION OF NEW PARTNERS IN LLP
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-event-based-filing#sec3">
                          CHANGE OF NAME OF LLP
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/epicorp-event-based-filing#sec4">
                          CHANGE OF REGSITERED OFFICE OF LLP
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-gst-filing"
                    >
                      GST FILING
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-xbrl-filing"
                      data="130_3"
                    >
                      XBRL FILING{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-annual-director-kyc"
                      data="131_3"
                    >
                      ANNUAL DIRECTOR KYC
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-secretarial-audit"
                      data="133_3"
                    >
                      SECRETARIAL AUDIT
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={4}
                  onClick={toggleDropdownRegulatory}
                >
                  Corporate Actions <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show4"
                  style={{ display: isOpenRegulatory ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-issue-allotment-of-shares"
                      data="642_4"
                    >
                      ISSUE AND ALLOTMENT OF SHARES
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-transfer-of-shares"
                      data="640_4"
                    >
                      TRANSFER OF SHARES
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-registration-modification-of-charges"
                      data="518_4"
                    >
                      REGISTRATION/
                      <br />
                      MODIFICATION OF CHARGES
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-change-company-name"
                      data="124_4"
                    >
                      CHANGE OF NAME OF THE COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-alteration-of-moa-aoa"
                      data="125_4"
                    >
                      ALTERATION OF MOA AND AOA <br />
                      OF THE COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-change-of-registered-address"
                      data="126_4"
                    >
                      CHANGE OF REGISTERED OFFICE
                      <br /> OF THE COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-changes-in-directors"
                      data="1120_4"
                    >
                      CHANGES IN DIRECTORS
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-change-of-auditors"
                      data="175_4"
                    >
                      CHANGE OF AUDITORS
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={5}
                  onClick={toggleDropdownEnvironmental}
                >
                  Conversions & Closure <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show5"
                  style={{ display: isOpenEnvironmental ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-closure-of-company"
                      data="420_5"
                    >
                      CLOSURE OF COMPANY
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-closure-of-llp"
                      data="635_5"
                    >
                      CLOSURE OF LLP
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link custom-main-dropdown"
                  data={6}
                  onClick={toggleDropdownSecretarial}
                >
                  Secretarial & Allied <i className="fa fa-angle-down ms-2" />
                </Link>
                <ul
                  className="dropdown-menu custom-main custom-main-dropdown-show5"
                  style={{ display: isOpenSecretarial ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-drafting-of-board-special-resolution"
                      data="420_5"
                    >
                      DRAFTING OF BOARD RESOLUTION
                      <br /> AND SPECIAL RESOLUTION
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-maintenance-of-minutes-of-board-general-meetings"
                      data="635_5"
                    >
                      MAINTENANCE OF MINUTES OF
                      <br /> BOARD AND GENERAL MEETINGS
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item custome_mobile_menu2"
                      to="/epicorp-maintenance-of-statutory-registers"
                      data="636_5"
                    >
                      MAINTENANCE OF
                      <br /> STATUTORY REGISTERS
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                {" "}
                <Link
                  className="nav-link"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  aria-label="dropdown"
                  onClick={toggleDropdownCompany}
                >
                  <i
                    className={`fa ${isOpenCompany ? "fa-close" : "fa-bars"}`}
                    style={{
                      backgroundColor: "#6c757d", // Change this to your desired color
                      borderRadius: "30px",
                      padding: "10px 20px",
                    }}
                  />
                </Link>
                <ul
                  className="dropdown-menu drop-more"
                  aria-labelledby="navbarDropdown"
                  data-bs-popper="none"
                  style={{ display: isOpenCompany ? "block" : "none" }}
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      style={{ color: "white" }}
                      to="/about"
                      onClick={() => {
                        setIsOpenCompany(false);
                        setIsOpen(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      style={{ color: "white" }}
                      to="/contact"
                      onClick={() => {
                        setIsOpenCompany(false);
                        setIsOpen(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
