import React, { Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import PageTracker from "./components/PageTracker";
const Thanks = React.lazy(() => import("./components/Thanks"));
const Footer = React.lazy(() => import("./components/Footer"));
const About = React.lazy(() => import("./components/About"));
const Contact = React.lazy(() => import("./components/Contact"));
const Services = React.lazy(() => import("./components/Services"));
const SubService = React.lazy(() => import("./components/Sub_Service"));
const FloatingButton = React.lazy(() => import("./components/FloatingButton"));

const Private = React.lazy(() => import("./services/company/Private"));
const OnePerson = React.lazy(() => import("./services/company/OnePerson"));
const LLP = React.lazy(() => import("./services/company/LLP"));
const Wholly = React.lazy(() => import("./services/company/Wholly"));
const Nidhi = React.lazy(() => import("./services/company/Nidhi"));
const Producer = React.lazy(() => import("./services/company/Producer"));
const NGO = React.lazy(() => import("./services/company/NGO"));
const StartUp = React.lazy(() => import("./services/company/StartUp"));

const TradeMark = React.lazy(() => import("./services/licence/TradeMark"));
const MSME = React.lazy(() => import("./services/licence/MSME"));
const FSSAI = React.lazy(() => import("./services/licence/FSSAI"));
const GST = React.lazy(() => import("./services/licence/GST"));
const IEC = React.lazy(() => import("./services/licence/IEC"));
const Apeda = React.lazy(() => import("./services/licence/Apeda"));
const RCMC = React.lazy(() => import("./services/licence/RCMC"));
const Spice = React.lazy(() => import("./services/licence/Spice"));
const NGOReg = React.lazy(() => import("./services/licence/NGOReg"));
const IER = React.lazy(() => import("./services/licence/IER"));

const Annual = React.lazy(() => import("./services/filing/Annual"));
const LLPFil = React.lazy(() => import("./services/filing/LLPFil"));
const Event = React.lazy(() => import("./services/filing/Event"));
const XBRL = React.lazy(() => import("./services/filing/XBRL"));
const KYC = React.lazy(() => import("./services/filing/KYC"));
const SECRETARIAL = React.lazy(() => import("./services/filing/SECRETARIAL"));
const GSTFil = React.lazy(() => import("./services/filing/GSTFil"));

const IAShares = React.lazy(() => import("./services/actions/IAShares"));
const TShares = React.lazy(() => import("./services/actions/TShares"));
const Charges = React.lazy(() => import("./services/actions/Charges"));
const Ccompany = React.lazy(() => import("./services/actions/Ccompany"));
const Amao = React.lazy(() => import("./services/actions/Amoa"));
const Coffice = React.lazy(() => import("./services/actions/Coffice"));
const Cdirector = React.lazy(() => import("./services/actions/Cdirector"));
const Cauditor = React.lazy(() => import("./services/actions/Cauditor"));

const CompanyC = React.lazy(() => import("./services/closure/CompanyC"));
const LLPC = React.lazy(() => import("./services/closure/LLPC"));

const Draft = React.lazy(() => import("./services/allied/Draft"));
const Mboard = React.lazy(() => import("./services/allied/Mboard"));
const Mregister = React.lazy(() => import("./services/allied/Mregister"));

const Company = React.lazy(() => import("./pages/Company"));
const License = React.lazy(() => import("./pages/License"));
const Filing = React.lazy(() => import("./pages/Filing"));
const Action = React.lazy(() => import("./pages/Actions"));
const Closure = React.lazy(() => import("./pages/Closure"));
const Allied = React.lazy(() => import("./pages/Allied"));

function PageLoader() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400); // Change this to the amount of time you want the loader to show
  }, [location.pathname]);

  return loading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        zIndex: 9999,
      }}
    >
      <Bars color="#09455F" height={100} width={100} timeout={400} />
    </div>
  ) : null;
}

function App() {
  return (
    <>
      <Router>
      <PageTracker />
        <Suspense fallback={<PageLoader />}>
          <Navbar />
          <PageLoader />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/subservice" element={<SubService />} />
            <Route path="/company" element={<Company />} />
            <Route path="/license" element={<License />} />
            <Route path="/filing" element={<Filing />} />
            <Route path="/action" element={<Action />} />
            <Route path="/closure" element={<Closure />} />
            <Route path="/allied" element={<Allied />} />
            <Route path="/thanks" element={<Thanks />} />

            {/* COMPANY REGISTRATION SERVICES */}
            <Route path="/epicorp-private-limited-company" element={<Private />} />
            <Route path="/epicorp-one-person-company" element={<OnePerson />} />
            <Route path="/epicorp-limited-liability-partnership" element={<LLP />} />
            <Route path="/epicorp-subsidiary-of-foreign-company" element={<Wholly />} />
            <Route path="/epicorp-nidhi-company" element={<Nidhi />} />
            <Route path="/epicorp-producer-farmer-company" element={<Producer />} />
            <Route path="/epicorp-NGO-company" element={<NGO />} />
            <Route path="/epicorp-start-up-india-registration" element={<StartUp />} />

            {/* REGISTRATION & LICENCES SERVICES */}
            <Route path="/epicorp-trademark-registration" element={<TradeMark />} />
            <Route path="/epicorp-msme-registration" element={<MSME />} />
            <Route path="/epicorp-fssai-registration-licences" element={<FSSAI />} />
            <Route path="/epicorp-gst-registration" element={<GST />} />
            <Route path="/epicorp-import-export-code-registration" element={<IEC />} />
            <Route path="/epicorp-apeda-registration" element={<Apeda />} />
            <Route path="/epicorp-rcmc-certificate-fieo" element={<RCMC />} />
            <Route path="/epicorp-spices-board-certificate" element={<Spice />} />
            <Route path="/epicorp-ngo-registration" element={<NGOReg />} />
            <Route path="/epicorp-import-export-registrations" element={<IER />} />

            {/* RETURN FILING SERVICES */}
            <Route path="/epicorp-annual-filing-of-companies" element={<Annual />} />
            <Route path="/epicorp-filing-of-llps" element={<LLPFil />} />
            <Route path="/epicorp-event-based-filing" element={<Event />} />
            <Route path="/epicorp-gst-filing" element={<GSTFil />} />
            <Route path="/epicorp-xbrl-filing" element={<XBRL />} />
            <Route path="/epicorp-annual-director-kyc" element={<KYC />} />
            <Route path="/epicorp-secretarial-audit" element={<SECRETARIAL />} />

            {/* CORPORATE ACTIONS */}
            <Route path="/epicorp-issue-allotment-of-shares" element={<IAShares />} />
            <Route path="/epicorp-transfer-of-shares" element={<TShares />} />
            <Route path="/epicorp-registration-modification-of-charges" element={<Charges />} />
            <Route path="/epicorp-change-company-name" element={<Ccompany />} />
            <Route path="/epicorp-alteration-of-moa-aoa" element={<Amao />} />
            <Route path="/epicorp-change-of-registered-address" element={<Coffice />} />
            <Route path="/epicorp-changes-in-directors" element={<Cdirector />} />
            <Route path="/epicorp-change-of-auditors" element={<Cauditor />} />

            {/* CONVERSIONS & CLOSURE */}
            <Route path="/epicorp-closure-of-company" element={<CompanyC />} />
            <Route path="/epicorp-closure-of-llp" element={<LLPC />} />

            {/* SECRETARIAL & ALLIED SERVICES */}
            <Route path="/epicorp-drafting-of-board-special-resolution" element={<Draft />} />
            <Route path="/epicorp-maintenance-of-minutes-of-board-general-meetings" element={<Mboard />} />
            <Route path="/epicorp-maintenance-of-statutory-registers" element={<Mregister />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
          <FloatingButton />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
