import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC5deD9XVqbyqKtLRlnrfxtKdUdP_xLGh4",
    authDomain: "epicorp-llp-2024.firebaseapp.com",
    projectId: "epicorp-llp-2024",
    storageBucket: "epicorp-llp-2024.appspot.com",
    messagingSenderId: "550349729101",
    appId: "1:550349729101:web:d0c038099c1a8b346a1b04",
    measurementId: "G-P1GMY46XCE"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(firebaseApp);
// Initialize Firestore and get a reference to the service
export const db = getFirestore(firebaseApp);
